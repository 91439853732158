import {
  Box,
  Flex,
  Text,
  IconButton,
  Image,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import { avatarAdminSubMenu } from "@impact-theory/app-avatar-arena-admin";
import { reportsSubMenu } from "@impact-theory/app-reports";
import {
  userAuthRoutes,
  userHasAccess,
  getUsersEmail,
} from "@impact-theory/app-auth";
import { allSystemGoSubMenu } from "@impact-theory/app-all-systems-go";
import { ituSubMenu } from "@impact-theory/app-itu-admin";

import { useEffect, useState } from "react";

export default function TopMenu() {
  const { isOpen, onToggle } = useDisclosure();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const result = await userHasAccess(["itu-admin"]);
        if (result) {
          setIsLoggedIn(true);
          setEmail(await getUsersEmail());
        } else {
          setIsLoggedIn(false);
          setEmail("");
        }
      } catch (err) {
        console.error(err);
      }
    };

    try {
      checkAccess();
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        // py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
          alignItems={"center"}
        >
          <Image
            objectFit="cover"
            src="https://d4wqkbeybl7q3.cloudfront.net/global/it-logo-stacked.png"
            h="40px"
            title="Impact Theory Management Console"
          />
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"heading"}
            fontWeight={"bold"}
            color={useColorModeValue("gray.800", "white")}
            as={Link}
            to={"/"}
          >
            Management Console
          </Text>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            {isLoggedIn && <DesktopNav />}
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          {(!isLoggedIn && (
            <Button
              as={"a"}
              fontSize={"sm"}
              fontWeight={400}
              variant={Link}
              href={userAuthRoutes.login}
            >
              Sign In
            </Button>
          )) || (
            <Flex gap={2} alignItems={"center"} fontSize={"sm"}>
              {email}
              <Button
                colorScheme="teal"
                as={"a"}
                fontSize={"sm"}
                fontWeight={400}
                variant={Link}
                href={userAuthRoutes.logout}
              >
                Logout
              </Button>
            </Flex>
          )}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        {isLoggedIn && <MobileNav />}
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Flex>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                to={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                <Box
                  p={2}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                    bg: "#0000002b",
                  }}
                  rounded="md"
                >
                  {navItem.label}
                </Box>
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Flex>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      to={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        to={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            bg={"#000"}
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} to={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

let NAV_ITEMS = [
  {
    label: "ITU Admin",
    href: ituSubMenu[0].href,
  },
  {
    label: "Avatar Arena",
    children: avatarAdminSubMenu,
    href: avatarAdminSubMenu[0].href,
  },
  {
    label: "Reports",
    children: reportsSubMenu,
    href: reportsSubMenu[0].href,
  },
  {
    label: "All Systems Go",
    children: allSystemGoSubMenu,
    href: allSystemGoSubMenu[0].href,
  },
  {
    label: "Settings",
    href: userAuthRoutes.settings,
  },
  {
    label: "Data Sync Hubspot",
    href: "/data-sync-hubspot",
  },
];
