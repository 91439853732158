import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import TopMenu from "./components/TopMenu";

import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <TopMenu />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
